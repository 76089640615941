import React from 'react';
import { mediumRubik } from '../../style/styledCSS';
import { msColors } from '../../style/themes/defaultTheme';

const SiteVersion = () => {
    return (
        <p
            style={{
                paddingLeft: '8px',
                ...mediumRubik,
                color: msColors.veryLightTextColor,
                textAlign: 'left',
            }}
        >
            Ver 1.6.1
        </p>
    );
};

export default SiteVersion;
